.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px;
}

.custom-modal-main {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


@media (max-width: 479px) {
  .modal-main {
    width: 90%;
    padding: 5px;
  }
}

.modal-close {
  float: right;
  font-size: 26px;
  width: 5%;
  border-radius: 10px;
  background-color: lightcoral;
}

@media (max-width: 479px) {
  .modal-close {
    font-size: 17px;
    position: absolute;
    right: 4px;
    width: 12%;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}