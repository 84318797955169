thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: "👇";
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: "☝️";
  display: inline-block;
  margin-left: 1em;
}
