.wsp-btn {
  // margin-left: 10px;
  // margin-bottom: 10px;
  // margin-top: 10px;
  display: inline-block;
}

.table-border-button {
  border-bottom: 2px solid black;
}
