@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wdth,wght@0,87.5,500;1,87.5,500&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

body {
  margin: 0;
  background-color: #f5f5f7;
}
