.premise-info {
  font-size: calc(15px + 1vmin);
  //line-height: 5vw;
  position: relative;
  top: 20px;
  left: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.premise-info-child {
  margin-left: 2px;
  font-size: calc(15px + 1vmin);
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.premise-table {
  margin: 15px;
  margin-left: 25px;
  margin-right: 25px;
}

@media (max-width: 425px) {
  .premise-table {
    margin: 15px;
    margin-left: 10px;
    margin-right: 10px;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.account-type {
  font-size: calc(15px + 1vmin);
  position: relative;
  float: right;
  right: 20px;
}

.admin-local-btn {
  font-size: calc(15px + 1vmin);
}

.contract-btn {
  margin-bottom: 25px;
}

.contract-actions-header {
  height: 10vh;
  margin-bottom: 20px;
}

@media (max-width: 479px) {
  .contract-actions-header {
    height: 5vh;
  }
  .contract-btn{
    margin-bottom: 10px;
  }
}

.contract-actions-title {
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
  font-size: 33px;
}

@media (max-width: 479px) {
  .contract-actions-title {
    font-size: 20px;
    text-align: left;
  }
}