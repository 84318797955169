$primary: rgb(70, 143, 161);
$primary-contrast: rgb(89, 236, 255);
$background-main: #efefef;

.table-container {
  overflow-x: auto;
  font-size: 20px;
}

@media (max-width: 479px) {
  .table-container {
    font-size: 5vw;
  }
}

.page {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mainContainer {
  position: absolute;
  width: 75%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
}

.secondContainer {
  padding: 10px;
}

.thirdContainer {
  text-align: center;
}

.header {
  min-height: 5vh;
  // display: flex;
  font-size: calc(20px + 4vmin);
  font-family: URW Chancery L, cursive;
}

.title {
  font-size: calc(15px + 3vmin);
  //line-height: 5vw;
  position: relative;
  top: 10px;
}

.logo-animated {
  height: 20vmin;
  width: 20vmin;
  animation: scale-up-center 1s linear alternate both;
  float: center;
}

.logo {
  height: 20vmin;
  width: 20vmin;
  margin-bottom: 15px;
  float: center;
}

.logo-title {
  height: 33vmin;
  width: 70vmin;
  display: block;
  margin: auto;
}

@media (max-width: 479px) {
  .logo-title {
    position: relative;
    right: 31px;
  }
}

.approved {
  background: lightgreen;
}

.columWithButton {
  display: table-cell;
  border-bottom: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  text-align: center;
  vertical-align: middle !important;
}

.not-approved {
  background: lightgray;
}

.rejected {
  background: lightcoral;
}

th button {
  background-color: inherit;
}

// BUTTONS ////////////////////////

th button {
  background-color: inherit;
}

// GRADIENTS /////////////////////

.background {
  background: $background-main;
}

// ANIMATIONS /////////

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

:root {
  --bs-body-bg: transparent;
}