.tab {
  width: 100%;
  border-bottom: 1px solid transparent;
  font-weight: bold;
  border-radius: 0;
  color: #8e8e8e;
  background: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0;
  padding-right: 0;
}

.tab:hover {
  color: #5983ef;
}

.tab[data-state='active'] {
  color: #5983ef;
  background-color: white;
  border-color: #5983ef;
  border-radius: 20px 20px 0 0;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  z-index: 9999;
}

.tab[data-state='inactive'] {
  border: 1px solid transparent;
}

/* Estilo general */
.tab-list {
  width: 100%;
  margin: 0 auto;
  z-index: 20;
  height: 6rem;
  display: flex;
  justify-content: space-around;
  gap: 0;
  border-radius: 12px;
  padding-top: 12px;

  position: relative;
  padding-left: 0;
  padding-right: 0;
  height: auto;
}

/* Estilo general */
.tab-container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
